'use client';

import * as React from 'react';
import * as LabelPrimitives from '@radix-ui/react-label';
import { cn } from '@/utils/cn';

const LabelRoot = React.forwardRef<
  React.ComponentRef<typeof LabelPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitives.Root> & {
    disabled?: boolean;
  }
>(({ className, disabled, ...rest }, forwardedRef) => {
  return (
    <LabelPrimitives.Root
      ref={forwardedRef}
      className={cn(
        'group cursor-pointer text-action text-neutral-900',
        'flex gap-0.5',
        className,
      )}
      aria-disabled={disabled}
      {...rest}
      data-oid='64n0x.5'
    />
  );
});
LabelRoot.displayName = 'LabelRoot';

function LabelAsterisk({
  className,
  children,
  ...rest
}: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={cn('text-body-s text-neutral-300', className)}
      {...rest}
      data-oid='da9gmcn'
    >
      *
    </span>
  );
}

export { LabelRoot as Label, LabelAsterisk as Asterisk };
