import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '@/utils/cn';

type SocialButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  asChild?: boolean;
};

export const SocialButton = React.forwardRef<
  HTMLButtonElement,
  SocialButtonProps
>(({ asChild, children, className, ...rest }, forwardedRef) => {
  const Component = asChild ? Slot : 'button';

  return (
    <Component
      ref={forwardedRef}
      className={cn(
        'shadow-action inline-flex h-9 items-center justify-center gap-2 whitespace-nowrap rounded-[9px] px-4 text-action text-neutral-900 ring-1 ring-neutral-200',
        'transition duration-300',
        // hover
        'hover:bg-neutral-100',
      )}
      {...rest}
      data-oid='mpa_jne'
    >
      {children}
    </Component>
  );
});
SocialButton.displayName = 'SocialButton';
