import { cn } from '@/utils/cn';

export function DashedDivider({ className }: { className?: string }) {
  return (
    <div
      className={cn('h-px w-full text-neutral-200', className)}
      style={{ background: 'linear-gradient(90deg, currentColor 2px, transparent 2px) 50% 50% / 4px 1px' }}
      role='separator'
    />
  );
}

export function DashedDivider4({ className }: { className?: string }) {
  return (
    <div
      className={cn('h-px w-full text-neutral-200', className)}
      style={{ background: 'linear-gradient(90deg, currentColor 4px, transparent 4px) 50% 50% / 8px 1px' }}
      role='separator'
    />
  );
}

export function DashedDivider6({ className }: { className?: string }) {
  return (
    <div
      className={cn('-mt-px h-px w-full', className)}
      style={{ background: 'linear-gradient(90deg, currentColor 6px, transparent 6px) 50% 50% / 12px 1px' }}
      role='separator'
    />
  );
}
