import * as React from 'react';
import { tv, type VariantProps } from '@/utils/tv';
import { Slot, Slottable } from '@radix-ui/react-slot';

export const buttonVariants = tv({
  base: [
    // base
    'relative inline-flex items-center justify-center overflow-hidden whitespace-nowrap',
    'transition duration-300',
    // before
    'before:absolute before:top-1/2 before:h-24 before:w-[35px] before:-translate-y-1/2 before:rounded-full before:bg-white/[.64] before:blur-[28px]',
    'before:pointer-events-none before:animate-shimmer',
    // 'before:-left-16 before:transition-all before:duration-500',
    // hover
    // 'hover:before:left-[calc(100%+64px)]',
    'focus:outline-0',
  ],
  variants: {
    size: {
      small: 'h-8 gap-2.5 rounded-[9px] px-3 text-action',
      medium: 'h-9 gap-3 rounded-[11px] px-4 text-action',
      large: 'h-10 gap-3 rounded-[11px] px-4 text-action',
    },
    color: {
      primary: [
        // base
        'bg-primary-normal text-neutral-0 shadow-primary-button',
        // hover
        'hover:bg-primary-normal/80',
      ],
      orange:
        'bg-orange-normal text-neutral-0 shadow-primary-button [--shadow-primary-button-color:#ED6408]',
      error:
        'bg-red-normal text-neutral-0 shadow-primary-button [--shadow-primary-button-color:#ef3a2f]',
      blue: 'bg-[#0779FF] text-neutral-0 shadow-primary-button [--shadow-primary-button-color:#0779FF]',
      purple:
        'bg-[#7C3AED] text-neutral-0 shadow-primary-button [--shadow-primary-button-color:#7C3AED]',
    },
  },
  defaultVariants: {
    color: 'primary',
    size: 'medium',
  },
});

type ButtonPrimaryProps = VariantProps<typeof buttonVariants> &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    asChild?: boolean;
    leadingIcon?: React.ElementType;
    trailingIcon?: React.ElementType;
  };

export const ButtonPrimary = React.forwardRef<
  HTMLButtonElement,
  ButtonPrimaryProps
>(
  (
    {
      size,
      color,
      asChild,
      leadingIcon: LeadingIcon,
      trailingIcon: TrailingIcon,
      children,
      className,
      ...rest
    },
    forwardedRef,
  ) => {
    const Component = asChild ? Slot : 'button';

    return (
      <Component
        ref={forwardedRef}
        className={buttonVariants({ color, size, class: className })}
        {...rest}
      >
        {LeadingIcon && <LeadingIcon className='-mx-1 size-4 shrink-0' />}
        <Slottable>{children}</Slottable>
        {TrailingIcon && <TrailingIcon className='-mx-1 size-4 shrink-0' />}
      </Component>
    );
  },
);
ButtonPrimary.displayName = 'ButtonPrimary';
