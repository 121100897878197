import { DashedDivider4 } from '@/components/ui/divider-dashed';

import IconBubbleInfo from '@public/icons/bubble-info.svg';

export function FooterCommon() {
  return (
    <span className='text-footnote text-neutral-400' data-oid='9-_rk9.'>
      All rights reserved © 2025 CreditCaptain
    </span>
  );
}

export function FooterLastStep() {
  return (
    <div data-oid='e9qzw_d'>
      <DashedDivider4 data-oid='3-ote.q' />
      <div
        className='flex flex-col justify-between gap-3 pt-6 sm:flex-row sm:items-center'
        data-oid='9.35aa-'
      >
        <div className='flex items-center gap-1.5' data-oid='otmgx71'>
          <IconBubbleInfo
            className='size-4 shrink-0 text-orange-normal'
            data-oid='czrq.of'
          />

          <div className='text-caption-m text-[#545454]' data-oid='_9x1vbz'>
            CreditCaptain doesn&apos;t check credit ∙{' '}
            <span className='text-footnote text-[#818181]' data-oid='.llcbsm'>
              it aims to boost scores with custom features.
            </span>
          </div>
        </div>
        <span
          className='text-footnote text-neutral-400 sm:text-right'
          data-oid='domkt2:'
        >
          All rights reserved © 2025 CreditCaptain
        </span>
      </div>
    </div>
  );
}
