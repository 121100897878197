// AlignUI Input v0.0.0

import * as React from 'react';
import type { PolymorphicComponentProps } from '@/utils/polymorphic';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '@/utils/cn';

function InputRoot({
  className,
  asChild,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & { asChild?: boolean }) {
  const Component = asChild ? Slot : 'div';

  return (
    <Component
      className={cn(
        'relative flex h-fit w-full overflow-hidden rounded-[9px] bg-neutral-0 shadow-action ring-1 ring-neutral-200',
        'divide-x divide-neutral-200',
        'transition duration-200',
        // hover
        'hover:ring-transparent',
        // focus within
        'focus-within:ring-transparent',
        // before
        'before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:opacity-0 before:ring-1 before:ring-inset before:ring-primary-normal',
        'before:transition before:duration-200',
        // focus within
        'focus-within:before:opacity-100',
        // hover
        'hover:before:opacity-100',
        className,
      )}
      {...rest}
    />
  );
}
InputRoot.displayName = 'InputRoot';

function InputWrapper({
  className,
  asChild,
  ...rest
}: React.HTMLAttributes<HTMLLabelElement> & { asChild?: boolean }) {
  const Component = asChild ? Slot : 'label';

  return (
    <Component
      className={cn(
        'group/input-wrapper',
        'flex w-full items-center gap-2 px-[9px]',
        className,
      )}
      {...rest}
    />
  );
}

const Input = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ className, type = 'text', ...rest }, forwardedRef) => {
  return (
    <input
      type={type}
      ref={forwardedRef}
      className={cn(
        'h-9 w-full bg-white text-body-s text-neutral-900 caret-primary-normal',
        // placeholder
        'placeholder:text-neutral-500',
        // focus
        'focus:outline-0 focus:outline-none',
        className,
      )}
      {...rest}
    />
  );
});
Input.displayName = 'Input';

function InputIcon({
  icon: Icon,
  className,
  ...rest
}: { icon: React.ElementType } & React.ComponentPropsWithoutRef<'div'>) {
  return (
    <Icon
      className={cn(
        'size-[18px] shrink-0 select-none text-neutral-500',
        className,
      )}
      {...rest}
    />
  );
}

export { InputRoot as Root, InputWrapper as Wrapper, Input, InputIcon as Icon };
